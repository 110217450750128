import React from "react";

export const FootnoteLink = ({ footnotes, start, end }) => {
  if (start < 1 || end > footnotes.length) return "";
  if (start === end || !end)
    return (
      <>
        [<a href={`#footnote-${start}`}>{`${start}`}</a>]
      </>
    );
  else
    return (
      <>
        [<a href={`#footnote-${start}`}>{`${start}-${end}`}</a>]
      </>
    );
};

export const useFootnotes = (initialFootnotes) => {
  const [footnotes, setFootnotes] = React.useState(initialFootnotes);
  const FootnoteLink = (props) => (
    <FootnoteLink footnotes={footnotes} {...props} />
  );
  const Footnotes = (props) => <Footnotes footnotes={footnotes} {...props} />;
  return [footnotes, setFootnotes, Footnotes];
};

export const Footnotes = ({ footnotes }) => {
  return (
    <>
      <hr />
      <h2 className="subtitle is-4">References</h2>
      <ul className="is-size-7">
        {footnotes.map((footnote, index) => (
          <li id={`footnote-${index + 1}`} key={index}>
            {`[${index + 1}] `}
            <span dangerouslySetInnerHTML={{ __html: footnote }} />
          </li>
        ))}
      </ul>
    </>
  );
};
