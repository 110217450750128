import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FootnoteLink, Footnotes, useFootnotes } from "../components/hooks/use-footnotes"

const footnotes = [
    "R.W. Baker, Membrane Technology and Applications, third ed., John Wiley & Sons, U.K., 2012. ",
    "S. Kim, E.M.V. Hoek, Modeling concentration polarization in reverse osmosis processes, Desalination 186 (2005) 111–128. ",
    "J.R. Werber, et al., The critical need for increased selectivity, not increased water permeability, for desalination membranes, Environ. Sci. Technol. Lett. 3 (2016) 112–120.",
]

const ConcentrationPolarization = () => {

    const [notes] = useFootnotes(footnotes)

    return (
        <Layout>
            <Seo title='Concentration polarization | Open Membrane Database' />
            <section className="section">
                <div className="container is-max-desktop">
                    <h1 className="title is-spaced">Concentration polarization</h1>
                    <p className="mb-3">
                        The membrane permeability and selectivity are often reported in terms of water flux (<i>J<sub>w</sub></i>) and observed rejection (<i>R<sub>obs</sub></i>). However, these properties are dependent on the operational conditions (e.g., salt concentration, solution pH, applied hydraulic pressure) and therefore cannot be used to compare the performance of membranes tested under different conditions. This necessitates the translation of <i>J<sub>w</sub></i> and <i>R<sub>obs</sub></i> values into A and B coefficients by accounting for concentration polarization (CP) in the diffusive boundary layer at the feed channel-membrane interface. Here, we provide a brief discussion on CP and the assumptions that were made for membranes deposited in the OMD. More detailed discussion can be found in <a href="https://www.sciencedirect.com/science/article/pii/S037673882100870X" target="_blank"  rel='noreferrer'>the OMD paper</a>. We also underline the urgent need for membrane researchers to report data on CP in their work.
                    </p>
                    <p className="mb-3">
                        CP is the accumulation of rejected solutes near the membrane surface, forming a so-called polarized layer. CP increases the osmotic pressure at the membrane surface, reducing the water flux due to a decreased effective driving force across the membrane at a fixed hydraulic pressure. The effect of CP on membrane performance can be approximated with film theory <FootnoteLink start={1} footnotes={notes} /> :
                    </p>
                </div>
            </section>
            <section className="section py-0 px-6">
                <div className='container is-max-desktop'>
                    <p className="mb-3">
                        <i>C<sub>m</sub></i>/<i>C<sub>f</sub></i> = (1-<i>R<sub>obs</sub></i>) + <i>R<sub>obs</sub></i> <i>exp</i> (<i>J<sub>w</sub></i>/<i>k<sub>f</sub></i>)
                        <span className="is-pulled-right">(1)</span>
                    </p>
                </div>
            </section>
            <section className="section">
                <div className="container is-max-desktop">
                    <p className="mb-3">
                        where <i>C<sub>m</sub></i>/<i>C<sub>f</sub></i> is the CP modulus (<i>i.e.</i>, the ratio of the solute concentration at the membrane surface to that of the bulk feed solution), Robs is the observed rejection, <i>J<sub>w</sub></i> is the water flux, and <i>k<sub>f</sub></i> is the overall feed-side mass transfer coefficient averaged for all feed solutes. No CP exists (<i>i.e.</i>, <i>C<sub>m</sub></i>/<i>C<sub>f</sub></i> =1) when <i>J<sub>w</sub></i>/<i>k<sub>f</sub></i> approaches zero or when the solute rejection is zero <FootnoteLink footnotes={notes} start={2} />. More detailed information on how to determine or estimate the mass transfer coefficients can be found in <a href="https://www.sciencedirect.com/science/article/pii/S037673882100870X" target="_blank"  rel='noreferrer'>the OMD paper</a>.
                    </p>
                    <p className="mb-3">
                        For membrane data uploaded to the OMD from previously published reports, the feed-side mass transfer coefficients were estimated as 100 L m<sup>-2</sup> h<sup>-1</sup> if their values were unreported in the original work <FootnoteLink footnotes={notes} start={3} />. Information on <i>k<sub>f</sub></i> for the deposited membranes in the OMD can be found on the OMD website, in the chart under the column "CP details". We note that assuming <i>k<sub>f</sub></i> can lead to some error in the computed A and B coefficients. Despite the uncertainty inherent to the assumptions, we posit that CP-based assumptions are necessary for comparing performance characteristics of RO membranes. Less than 5% of the 650 membranes included in the OMD at launch were associated with reported CP moduli or <i>k<sub>f</sub></i> in their original documents. The lack of CP moduli reporting in membrane literature is evidently extensive. We urge the membrane community to report data on CP to allow more accurate and systemic membrane performance comparison.
                    </p>
                </div>
            </section >
            <section className='section'>
                <div className="container is-max-desktop">
                    <Footnotes footnotes={footnotes} />
                </div>
            </section>
        </Layout >
    )
}

export default ConcentrationPolarization
